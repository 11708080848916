import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Form`}</inlineCode>{` merupakan sebuah unsur UI - biasanya berupa sekelompok input yang digunakan oleh user mengisi informasi.`}</p>
    </PageDescription>
    <h2>{`Variant`}</h2>
    <h3>{`Form Group`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Form Group" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAA8ElEQVQoz61Sy3LCMAzM/39Xv6BHDp2hQFtI4iR+2zCzSDZOnNDhxGHttSWtdyQ3xkW8Ew0v2oaX2ObU5y1vCnHhlnbrryteCtbnzBklVvKSQ0Xk56+DNh6tkJlbj0snILVNhVI7nNt+FhCjghjkQ9QvDp2P6GTAx+c3tNY4tgpf+xMFQxZUBpzDghcWNA6KIEaJfphIMGKiHEVmkqClZVABu8OQkvk1TuYXWWCcFBWF5PR4+k1nH2/oxZjgA7WhdphJSC/V/TK0szNbYnOvljgj11VDKU1+mu48vVjhv/vMV0NZxr79LgtefZsnh+/EHVM5BuSwcPLsAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/98f5917a7ffdce90edef81ef1673c5e7/3cbba/group.png",
              "srcSet": ["/static/98f5917a7ffdce90edef81ef1673c5e7/7fc1e/group.png 288w", "/static/98f5917a7ffdce90edef81ef1673c5e7/a5df1/group.png 576w", "/static/98f5917a7ffdce90edef81ef1673c5e7/3cbba/group.png 1152w", "/static/98f5917a7ffdce90edef81ef1673c5e7/7dc98/group.png 1200w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h3>{`Form Inline`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Form Inline" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAA8klEQVQoz5WS627DIAyF8/7vtgeYVGmb1AsrSQgYArSVTg1pooQRTf1hORj8+Th24/wNs9kxLn79vfhXfLK/sfSuqYFK4DuxplQ3hjvb5Nex2rtadxuF5ALaTuFbEESrMXLMWA/ZDdkbcvxuUtIpg8FYWOf5HF6MuAVq8iAifBwUPr8u8GECni5XvnNQg4FmSCosrh2UJi5iEeKOwl5byLbHURJ+jiJXTMlnIVlNAmoMDJm7SQWUUly4GMrm38QHTy5wSzqfE+hX9tlndax4ziFuN8WMHRfGzpT3h1KuVXUo/+1hfU2Ku8yI7wFdZffW+cmezScGYN1M8/AAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/4b2fc96c04d70704da0c4ed4fc626d28/3cbba/inline.png",
              "srcSet": ["/static/4b2fc96c04d70704da0c4ed4fc626d28/7fc1e/inline.png 288w", "/static/4b2fc96c04d70704da0c4ed4fc626d28/a5df1/inline.png 576w", "/static/4b2fc96c04d70704da0c4ed4fc626d28/3cbba/inline.png 1152w", "/static/4b2fc96c04d70704da0c4ed4fc626d28/7dc98/inline.png 1200w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      